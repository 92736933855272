.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: 100vh;
  background-color: black;
  color: white;
  flex-direction: column;
}

.lucasbarga-logo {
  height: 50vmin;
  width: fit-content;
  pointer-events: none;
}
